var $ = jQuery.noConflict();

$(document).ready(function($) {
    "use strict";

    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 70)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    $('#slider1').revolution({
        sliderType: "standard",
        sliderLayout: "auto",
        delay: 5000,
        gridwidth: 1230,
        gridheight: 720,
        navigation: {
            mouseScrollNavigation: "off",
            onHoverStop: "off",
        }
    });

    $('.bxslider').bxSlider({
        mode: 'horizontal'
    });

    $('.parallax').appear(function() {
        $.stellar({
            horizontalScrolling: false,
            verticalOffset: 0,
            parallaxBackgrounds: true,
            responsive: true
        });
    });

    $('.statistic-post').appear(function() {
        $('.timer').countTo({
            speed: 4000,
            refreshInterval: 60,
            formatter: function (value, options) {
                return value.toFixed(options.decimals);
            }
        });
    });
    
    var owl = $("#owl-example");
    owl.owlCarousel({
        navigation : true,
        afterInit : function(elem){
            var that = this;
            that.owlControls.appendTo(elem);
        },
        items: 3,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 2],
        itemsTablet: [768, 1],
        itemsTabletSmall: false,
        itemsMobile: [479, 1]
    });

    $(".buttons a.next").click(function(){
        owl.trigger('owl.next');
    })
    $(".buttons a.prev").click(function(){
        owl.trigger('owl.prev');
    })

    var map;
    function initialize() {
        var mapOptions = {
            zoom: 14,
            center: new google.maps.LatLng(47.178436, 18.441025),
            disableDefaultUI: true,
            minZoom: 4,
            zoomControl: false,
            scrollwheel: false
        };
        map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
        setTimeout(function(){
            marker.setMap(map);
        }, 700);
    }

    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(47.178436, 18.441025),
        animation: google.maps.Animation.DROP,
        icon: 'assets/images/marker.png?version4',
        map: map
    });

    google.maps.event.addDomListener(window, 'load', initialize);

});
